/* 预写样式部分 */
li{
    list-style: none;
}
.float-left{
    float: left;
}
.float-right{
    float: right;
}
.icon-wechat{
    display: inline-block;
    background: url("../img/icon_wechat.png") 0 0 no-repeat;
    background-size: 98%;
}
.icon-sina{
    display: inline-block;
    background: url("../img/icon_sina.png") 0 0 no-repeat;
    background-size: 98%;
}
.icon-project-detail{
    display: inline-block;
    background: url("../img/icon_project_detail.png") 0 0 no-repeat;
    background-size: 98%;
}
.can-click{
    cursor: pointer;
}

/* 样式覆盖部分 */
.fp-controlArrow{
    display: none;
}
.fp-slidesNav.fp-bottom {
    bottom: 60px;
}
.fp-slidesNav.fp-bottom ul{
    text-align: center;
}
#fp-nav ul li a span, .fp-slidesNav ul li a span{
    background: #fff;
}

/*------------首页-----------*/
/* 首屏 */
#fullpage{
    position: relative;
    background: -webkit-linear-gradient(-51deg, #4ec3f4, #1f9dcb, #0c4fab);
    background: -moz-linear-gradient(-51deg, #4ec3f4, #1f9dcb, #0c4fab);
    background: -o-linear-gradient(-51deg, #4ec3f4, #1f9dcb, #0c4fab);
    background: linear-gradient(-51deg, #4ec3f4, #1f9dcb, #0c4fab);
    height: 100vh;
    max-height: 100vh;
}
#first-screen .fp-scroller{
    max-height: 100vh;
}
#fullpage #header{
    position: absolute;
    top: 2vw;
    left: 8.5vw;
    z-index: 10;
}
#fullpage #header #logo{
    margin-right: 5vw;
}
#fullpage #header .nav li{
    margin-right: 3vw;
    text-align: center;
}
#fullpage #header .nav a{
    color:#fff;
    font-size:18px;
    position: relative;
    display: block;
    padding: 10px 15px;
    line-height: 1.4;
}
#fullpage #header .nav a:hover{
    background: none;
}
#fullpage #header .nav a:after{
    content: '';
    display: inline-block;
    position: absolute;
    left: 50%;
    bottom: 0;
    background: #ff7a2a;
    width: 0;
    height: 4px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -ms-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
}
#fullpage #header .nav a:hover:after{
    width: 80%;
    left: 10%;
    right: 0;
}
#fullpage #header .login, #fullpage #header .register{
    line-height: 70px;
}
#fullpage #header .register{
    margin-left: 2.6vw;
    margin-right: 15px;
}
#fullpage #header .login a, #fullpage #header .register a{
    color: #fff;
    font-size: 16px;
    padding: 4px 10px;
    border: 1px solid #fff;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-transition: all 100ms ease-in-out;
    -moz-transition: all 100ms ease-in-out;
    -ms-transition: all 100ms ease-in-out;
    -o-transition: all 100ms ease-in-out;
    transition: all 100ms ease-in-out;
}
#fullpage #header .login a:hover, #fullpage #header .register a:hover{
    text-decoration: none;
}
#fullpage #header .swiper-slide {
    margin-right: 0 !important;
}
.slide-left{
    padding: 18% 6% 12% 14%;
    width: 50%;
    color: #fff;
}
.slide-left p{
    font-size: 18px;
    line-height: 2.5;
}
.slide-left h2{
    font-size: 74px;
    text-shadow: 5px 0 5px rgba(0,0,0,0.25);
    margin-top: 10%;
}
.slide-left div{
    margin-top: 20%;
    font-size: 18px;
}
.slide-left div span{
    width: 2vw;
    height: 2vw;
    margin-left: 2vw;
    vertical-align: middle;
}
.slide-left div span:last-child{
    margin-left: 1vw;
}
.slide-right {
    width: 50%;
    text-align: right;
}
.slide-right img{
    width: 100%;
}
.swiper-slide .slide-bottom{
    margin-top: 3vw;
    text-align: center;
    color: #ffffff;
    line-height: 1.5;
}
.swiper-slide .slide-bottom h2{
    font-size: 1.5625vw;
    font-weight: bold;
}
.swiper-slide .slide-bottom p{
    margin-top: 1vw;
    font-size: 1vw;
}
.slide-left-new{
    padding-top: 17.7vw;
    padding-left: 7.3vw;
    width: 32.5vw;
    color: #fff;
}
.swiper-slide .slide-left-new h2{
    font-size: 1.5625vw;
    font-weight: bold;
    margin-bottom: 3vw;
}
.swiper-slide .slide-left-new p{
    font-size: 1vw;
    line-height: 2;
}
.slide-right-new{
    padding-top: 10vw;
    padding-right: 5vw;
}
.swiper-slide .slider-banner1{
    width: 70vw;
    margin-top: 8vw;
    margin-left: 15vw;
}
.swiper-slide .slider-banner2{
    width: 40vw;
    margin-top: 10vw;
    margin-left: 30vw;
}
.swiper-slide .slider-banner3{
    width: 45vw;
}
.swiper-slide .slider-banner4{
    width: 45vw;
}
#first-screen .slide:last-child .slide-right{
    text-align: left;
}
/* 关于我们 */
#about-us{
    padding: 6% 16% 8% 16%;
}
#about-us .header-row{
    text-align: center;
    margin-bottom: 10%;
}
#about-us .header-row h2, #about-us .header-row h3{
    margin-top: 0;
    margin-bottom: 0;
    color: #01237c;
    font-size: 36px;
}
#about-us .header-row h3{
    letter-spacing: 2px;
}
#about-us .content-row>div{
    width: 50%;
}
#about-us .content-row .float-left img{
    width: 90%;
}
#about-us .content-row .float-right h4{
    margin-top: -8px;
    margin-bottom: 40px;
    text-align: center;
    font-size: 28px;
    line-height: 54px;
    color:#06277f;
}
#about-us .content-row .float-right h4:first-line{
    font-size: 30px;
}
#about-us .content-row .float-right p{
    font-size: 18px;
    line-height: 40px;
    color: #666666;
}
#about-us .content-row .float-right>div{
    text-align: right;
    margin-top: 6%;
}
#about-us .content-row .float-right a{
    display: inline-block;
    width: 100px;
    height: 32px;
    background: none;
    border: 1px solid #2a3aa2;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #2a3597;
}
#about-us .content-row .float-right a:hover{
    text-decoration: none;
}
/* 最近更新的项目 */
#projects{
    height: 45vw;
    background: url("../img/projects_bg.jpg") 0 0 no-repeat;
    background-size: cover;
    padding: 5% 5%;
}
#projects h2{
    margin-bottom: 10%;
    text-align: center;
    letter-spacing: 2px;
    font-size: 36px;
    color: #fff;
}
#projects h2:before, #projects h2:after{
    content: '';
    display: inline-block;
    width: 30px;
    height: 1px;
    background: #b7b7b7;
    margin-right: 20px;
    vertical-align: super;
}
#projects h2:after{
    margin-right: 0;
    margin-left: 20px;
}
#projects ul{
    padding: 0;
}
#projects ul li{
    width: 23.5%;
    margin-right: 2%;
    margin-bottom: 2%;
    padding: 6px;
    background: #fff;
    position: relative;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 1px 0 1px rgba(0,0,0,0.1);
    -moz-box-shadow: 1px 0 1px rgba(0,0,0,0.1);
    box-shadow: 1px 0 1px rgba(0,0,0,0.1);
}
#projects ul li:nth-child(4n){
    margin-right: 0;
}
#projects ul li div img{
    width: 100%;
}
#projects ul li p{
    font-size: 18px;
    color: #2d3032;
    line-height: 30px;
    margin: 20px 0;
    padding-left: 5%;
}
#projects ul li>i{
    position: absolute;
    right: 5%;
    bottom: 20%;
    width: 46px;
    height: 46px;
    line-height: 46px;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    text-align: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: -webkit-linear-gradient(-90deg, #8471f6, #44a2e4);
    background: -moz-linear-gradient(-90deg, #8471f6, #44a2e4);
    background: -ms-linear-gradient(-90deg, #8471f6, #44a2e4);
    background: -o-linear-gradient(-90deg, #8471f6, #44a2e4);
    background: linear-gradient(-90deg, #8471f6, #44a2e4);
}
#projects ul li>i.icon-free{
    font-size: 13px;
    background: -webkit-linear-gradient(90deg, #45d269, #29cbb2);
    background: -moz-linear-gradient(90deg, #45d269, #29cbb2);
    background: -ms-linear-gradient(90deg, #45d269, #29cbb2);
    background: -o-linear-gradient(90deg, #45d269, #29cbb2);
    background: linear-gradient(90deg, #45d269, #29cbb2);
}
#projects ul li .project-shadow{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(25,33,43,0.6);
    opacity: 0;
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    -ms-transition: all 200ms;
    -o-transition: all 200ms;
    transition: all 200ms;
}
#projects ul li:hover .project-shadow{
    opacity: 1;
}
#projects ul li .project-shadow a{
    display: block;
    width: 144px;
    height: 46px;
    position: absolute;
    top: 40%;
    left: 50%;
    margin-left: -72px;
    line-height: 46px;
    color: #fff;
    font-size: 16px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    background: -webkit-linear-gradient(-90deg, #8471f6, #44a2e4);
    background: -moz-linear-gradient(-90deg, #8471f6, #44a2e4);
    background: -ms-linear-gradient(-90deg, #8471f6, #44a2e4);
    background: -o-linear-gradient(-90deg, #8471f6, #44a2e4);
    background: linear-gradient(-90deg, #8471f6, #44a2e4);
    text-align: center;
}
#projects ul li .project-shadow a:hover{
    text-decoration: none;
}
#projects ul li .project-shadow a i{
    width: 20px;
    height: 18px;
    margin-right: 16px;
    vertical-align: middle;
    margin-top: -2px;
}
#projects>div{
    margin-top: 8%;
    text-align: center;
}
#projects .view-all-projects{
    position: relative;
    display: inline-block;
    width: 11.4vw;
    height: 3vw;
    line-height: 3vw;
    text-align: center;
    color: #fff;
    font-size: 18px;
    background: -webkit-linear-gradient(90deg, #45d269, #29cbb2);
    background: -moz-linear-gradient(90deg, #45d269, #29cbb2);
    background: -ms-linear-gradient(90deg, #45d269, #29cbb2);
    background: -o-linear-gradient(90deg, #45d269, #29cbb2);
    background: linear-gradient(90deg, #45d269, #29cbb2);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}
#projects .view-all-projects:hover{
    text-decoration: none;
}
#projects .view-all-projects i{
    position: absolute;
    top: 0;
    right: 1.5vw;
    font-style: normal;
}
/* 合作伙伴/竞争优势 */
#partners{
    background: #f3f3fd;
    padding: 5% 16%;
}
#partners h4, #partners h2{
    text-align: center;
}
#partners h4{
    color: #666;
    font-size: 16px;
}
#partners h2{
    color: #01237c;
    font-size: 36px;
    margin-top: 10px;
    margin-bottom: 6.25vw;
}
#partners h4:before, #partners h4:after{
    content: '';
    display: inline-block;
    width: 30px;
    height: 1px;
    background: #b7b7b7;
    margin-right: 20px;
    vertical-align: super;
}
#partners h4:after{
    margin-right: 0;
    margin-left: 20px;
}
#partners ul{
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
#partners ul:nth-child(3){
    margin-bottom: 5%;
}
#partners ul li{
    flex: 1 0 0;
    margin-right: 4.6%;
}
#partners ul li:last-child{
    margin-right: 0;
}
#partners ul li img{
    width: 100%;
}
/* 最新资讯 */
#news{
    background: #f3f3fd;
    padding-bottom: 7%;
}
#news h4, #news h2{
    text-align: center;
}
#news h4{
    color: #666;
    font-size: 16px;
    margin-top: 0;
}
#news h2{
    color: #01237c;
    font-size: 36px;
    margin-top: 10px;
    margin-bottom: 5vw;
}
#news h4:before, #news h4:after{
    content: '';
    display: inline-block;
    width: 30px;
    height: 1px;
    background: #b7b7b7;
    margin-right: 20px;
    vertical-align: super;
}
#news h4:after{
    margin-right: 0;
    margin-left: 20px;
}
#news .news-swiper-container {
    padding-top:20px;
    height: 400px;
}
#news .news-swiper-container ul li{
    width: 400px;
    height: 230px;
    margin-right: 25px;
    background: #fff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 30px rgba(0,0,0,0.15);
    -moz-box-shadow: 0 0 30px rgba(0,0,0,0.15);
    box-shadow: 0 0 30px rgba(0,0,0,0.15);
    display: flex;
    overflow: hidden;
    cursor: pointer;
}
#news .news-swiper-container ul li .side-image{
    width: 0;
}
#news .news-swiper-container ul li .side-image img{
    width: 100%;
}
#news .news-swiper-container ul li .content{
    width: 100%;
    position: relative;
    padding: 20px;
}
#news .news-swiper-container ul li:hover .side-image, #news .news-swiper-container ul li.active .side-image{
    width: 35%;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}
#news .news-swiper-container ul li:hover .content, #news .news-swiper-container ul li.active .content{
    width: 65%;
}
#news .news-swiper-container ul li:hover .content p, #news .news-swiper-container ul li.active .content p{
    height: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
#news .news-swiper-container ul li:hover .content div, #news .news-swiper-container ul li.active .content div{
    width: 220px;
}
#news .news-swiper-container ul li h3{
    font-size: 20px;
    margin-top: 0;
    color: #404040;
}
#news .news-swiper-container ul li h5{
    font-size: 12px;
    color: #666;
}
#news .news-swiper-container ul li hr{
    width: 60px;
    height: 1px;
    background: #6e45e2;
    margin-left: 0;
    margin-bottom: 12px;
    margin-top: 16px;
}
#news .news-swiper-container ul li p{
    font-size: 13px;
    line-height: 1.7;
    color: #666;
}
#news .news-swiper-container ul li .content div{
    position: absolute;
    left: 20px;
    bottom: 20px;
    width: 360px;
}
#news .news-swiper-container ul li .content div a{
    color: #9fa4af;
    font-size: 12px;
}
#news .news-swiper-container ul li .content div a:hover{
    text-decoration: none;
}
#news .news-swiper-container ul li .content div a i{
    display: inline-block;
    width: 13px;
    height: 13px;
    background: url(sprite.png) -78px 0 no-repeat;
    vertical-align: text-top;
    margin-right: 16px;
}
#news .news-swiper-container ul li .content div span{
    display: inline-block;
    width: 26px;
    height: 26px;
    background: url(sprite.png) 0 0 no-repeat;
    float: right;
}
#news .news-swiper-container ul li:nth-child(2) .content div span, #news .news-swiper-container ul li:nth-child(6) .content div span{
    background-position: -26px 0;
}
#news .news-swiper-container ul li:nth-child(3) .content div span{
    background-position: -52px 0;
}
#news .news-swiper-container ul li>a{
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
#news .swiper-pagination .swiper-pagination-bullet{
    width: 10px;
    height: 10px;
    background: #01237c;
    margin: 0 15px;
}
/* 业务咨询和收费计划 */
#charging-container{
    width: 100%;
    height: 115vw;
    background: url("../img/charging_bg.jpg") 0 0 no-repeat;
    background-size: cover;
}
#services{
    padding: 13% 24% 22% 54%;
}
#services h4{
    color: #666;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
}
#services h2{
    color: #01237c;
    font-size: 32px;
    margin-top: 10px;
    margin-bottom: 60px;
    text-align: center;
}
#services h4:before, #services h4:after{
    content: '';
    display: inline-block;
    width: 20px;
    height: 1px;
    background: #b7b7b7;
    margin-right: 10px;
    vertical-align: super;
}
#services h4:after{
    margin-right: 0;
    margin-left: 10px;
}
#services p{
    font-size: 16px;
    color: #666;
    line-height: 1.5;
}
#charging-items{
    /*padding: 8.5% 21%;*/
}
#charging-items>h4, #charging-items>h2, #charging-items>h5{
    text-align: center;
}
#charging-items>h4{
    color: #666;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 15px;
}
#charging-items>h2{
    color: #01237c;
    font-size: 36px;
    margin-top: 10px;
    margin-bottom: 16px;
}
#charging-items>h4:before, #charging-items>h4:after{
    content: '';
    display: inline-block;
    width: 30px;
    height: 1px;
    background: #b7b7b7;
    margin-right: 20px;
    vertical-align: super;
}
#charging-items>h4:after{
    margin-right: 0;
    margin-left: 20px;
}
#charging-items>h5{
    font-size: 20px;
    color: #404040;
    margin-bottom: 50px;
}
#charging-items .charging-nav{
    width: 48%;
    height: 60px;
    line-height: 60px;
    margin: 0 auto 80px auto;
    border-bottom: 1px solid #e6e6e6;
}
#charging-items .charging-nav span{
    float: left;
    width: 33.33%;
    height: 100%;
    font-size: 18px;
    color: #666;
    text-align: center;
}
#charging-items .charging-nav span.charging-active{
    border-bottom: 2px solid #01237c;
}

#charging-items .charging-nav span:hover{
    text-decoration: none;
}
#charging-items .charging-container{
    overflow: hidden;
}
#charging-items .charging-container ul{
    width: 300vw;
    margin: 0;
    padding: 0;
}
#charging-items .charging-container ul li{
    flex: 1 0 0;
    margin-right: 32px;
    height: 13vw;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    border: 1px solid #808fd1;
    background: #fff;
    text-align: center;
    overflow: hidden;
}
#charging-items .charging-container ul li .charge-top-part{
    height: 35%;
    background: #f3f3fd;
    padding-top: 48px;
    color: #404040;
}
#charging-items .charging-container ul li .charge-top-part h4{
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 14px;
}
#charging-items .charging-container ul li .charge-top-part h5{
    font-size: 60px;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1;
}
#charging-items .charging-container ul li .charge-top-part p{
    font-size: 14px;
}
#charging-items .charging-container ul li .charge-bottom-part p{
    font-size: 16px;
    line-height: 3;
    color: #808080;
    margin-top: 16px;
}
#charging-items .charging-container ul li .charge-bottom-part div{
    margin-top: 20px;
}
#charging-items .charging-container ul li .charge-bottom-part span{
    display: inline-block;
    width: 170px;
    height: 48px;
    line-height: 48px;
    border: 1px solid #01237c;
    font-size: 16px;
    color: #141a81;
}
#charging-items .charging-container ul li .charge-bottom-part span:hover{
    text-decoration: none;
}
#charging-items .charging-container ul li:hover .charge-top-part, #charging-items .charging-container ul li:hover .charge-bottom-part span{
    background: -webkit-linear-gradient(150deg, #1041d3, #15095e);
    background: -moz-linear-gradient(150deg, #1041d3, #15095e);
    background: -ms-linear-gradient(150deg, #1041d3, #15095e);
    background: -o-linear-gradient(150deg, #1041d3, #15095e);
    background: linear-gradient(150deg, #1041d3, #15095e);
    color: #fff;
}
/* 我们的团队 */
#team{
    padding: 6% 17.7%;
}
#team .header-row{
    text-align: center;
    margin-bottom: 6%;
}
#team .charging-container ul li{
    background: rgb(243,243,253);
    color: #404040;
}
#team .charging-container .title{
    width: 100%;
    height: 2.25vw;
    font-weight: 700;
    line-height: 2.25vw;
    text-align: center;
    margin-top: 1.5vw;
}
#team .charging-container .price{
    width: 100%;
    height: 2.25vw;
    line-height: 2.25vw;
    text-align: center;
}
#team .charging-container .odds{
    width: 100%;
    height: 2.25vw;
    line-height: 2.25vw;
    text-align: center;
}
#team .charging-container .odds>span{
    padding: 2px 7px;
    text-align: center;
    margin-right: 3px;
    border-radius: 2px;
    background: #efefef;
}
#team .charging-container .odds>span:last-child{
    padding: 2px 7px;
    text-align: center;
    margin-right: 0;
    border-radius: 2px;
    background: #efefef;
}
#team .charging-container .toDetail{
    width: 50%;
    margin-left: 25%;
    height: 2vw;
    line-height: 2vw;
    margin-top: .8vw;
    text-align: center;
    border: 1px solid #132190;
    border-radius: 8px;
    cursor: pointer;
    background: white;
}
#team .charging-container .toDetail:hover{
    background: #132190;
    color: white;
}
#team .header-row h2, #team .header-row h3{
    margin-top: 0;
    margin-bottom: 20px;
    color: #01237c;
    font-size: 36px;
}
#team .header-row h3{
    letter-spacing: 1px;
}
#team .content-row{
    position: relative;
}
#team .content-row .member-container{
    width: 100%;
    padding: 40px 20px;
    overflow: hidden;
    position: relative;
    height: 320px;
}
#team .content-row ul{
    /*display: flex;*/
    width: 105vw;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 40px;
    left: 20px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}
#team .content-row ul li{
    /*flex: 1 0 0;*/
    float: left;
    width: 8.85vw;
    height: 12.5vw;
    margin-right: 1.66vw;
    border: 2px solid #d2d2d2;
    text-align: center;
    padding-top: 20px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}
#team .content-row ul li:last-child{
    margin-right: 0;
}
#team .content-row ul li:hover{
    cursor: pointer;
}
#team .content-row ul li.active-member{
    /*-webkit-box-shadow: 0 0 16px rgba(0,0,0,0.3);*/
    /*-moz-box-shadow: 0 0 16px rgba(0,0,0,0.3);*/
    /*box-shadow: 0 0 16px rgba(0,0,0,0.3);*/
    border: 2px solid #01237c;
}
#team .content-row ul li img{
    width: 70%;
}
#team .content-row ul li .member-name{
    color: #01237c;
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 2px;
}
#team .content-row ul li .member-title{
    color: #01237c;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0;
}
#team .content-row ul li .member-desc{
    display: none;
}
#team .content-row .short-description{
    margin-top: 20px;
    color: #01237c;
    font-size: 21px;
    line-height: 1.5;
    height: 108px;
}
#team .content-row button{
    position: absolute;
    top: 30%;
    display: inline-block;
    width: 20px;
    height: 52px;
    background: url(sprite.png) 0 -71px no-repeat;
    border: none;
    outline: none;
}
#team .content-row button:focus{
    outline: none;
}
#team .content-row button.prev-member{
    left: -50px;
}
#team .content-row button.next-member{
    right: -50px;
    background-position: -21px -71px;
}
/* 地图地址 */
#location img{
    width: 100%;
}
/* footer */
#index-footer{
    background: #126fb7;
    color: #fff;
}
#index-footer .logo{
    text-align: center;
    padding: 20px 0;
}
#index-footer .logo img{
    width: 10.4vw;
}
#index-footer .info{
    border-top: 1px solid #0142a2;
    border-bottom: 1px solid #0142a2;
    padding: 2.6vw 0;
}
#index-footer .info ul{
    padding: 0 19%;
    display: flex;
}
#index-footer .info ul li{
    flex: 1 0 0;
    margin-right: 17%;
    display: flex;
    line-height: 1.5;
}
#index-footer .info ul li:last-child{
    margin-right: 0;
}
#index-footer .info .left{
    margin-right: 14px;
}
#index-footer .info .left div{
    width: 36px;
    height: 36px;
    margin: 0 auto;
    background: url(sprite.png) 0 -33px no-repeat;
}
#index-footer .info .left .icon-mail{
    background-position: -36px -33px;
}
#index-footer .info .left .icon-address{
    background-position: -72px -33px;
}
#index-footer .info .left p{
    font-size: 14px;
    color: #a8ceff;
}
#index-footer .info .right p{
    font-size: 18px;
    margin-top: 10px;
}
#index-footer .info ul li:first-child .right p{
    margin-top: 0;
}
#index-footer .info ul li:last-child .right p{
    font-size: 14px;
}
#index-footer .copyright{
    text-align: center;
    padding: 20px 0 32px 0;
    font-size: 13px;
}
/* 登录注册部分 */
#register, #login{
    background: rgba(0,0,0,0.7);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: none;
    z-index: 1000;
}
#register>div, #login>div{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 350px;
    height: 460px;
    margin-top: -230px;
    margin-left: -175px;
    text-align: center;
    z-index: 1000;
}
#register h3, #login h3{
    color: #fff;
    font-size: 24px;
    margin-bottom: 44px;
}
#login form input,
#register form input{
    width: 280px;
    height: 48px;
    line-height: 48px;
    background: none;
    border: 1px solid #fff;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    border-radius: 24px;
    color: #fff;
    font-size: 14px;
    padding: 0 24px;
    outline: none;
    margin-bottom: 20px;
}
#register form input:focus,
#login form input:focus{
    outline: none;
}
#register form input[type=submit],
#login form input[type=submit]{
    border: none;
    background: #00a0e9;
    font-size: 18px;
    letter-spacing: 4px;
    margin-top: 20px;
}
#register form input::-webkit-input-placeholder{
    color: #fff;
}
#login form input::-webkit-input-placeholder{
    color: #fff;
}
#register form input:-moz-placeholder{
    color: #fff;
    opacity: 1;
}
#login form input:-moz-placeholder{
    color: #fff;
    opacity: 1;
}
#register form input::-moz-placeholder{
    color: #fff;
    opacity: 1;
}
#login form input::-moz-placeholder{
    color: #fff;
    opacity: 1;
}
#register form input::-ms-input-placeholder{
    color: #fff;
}
#login form input::-ms-input-placeholder{
    color: #fff;
}
#login .forget-password{
    text-align: right;
    padding: 0 50px;
}
#login .forget-password a{
    color: #73d0fa;
}
#login a.register{
    font-size: 18px;
    color: #73d0fa;
    letter-spacing: 4px;
}
#register form>div{
    position: relative;
}
#register form>div a{
    position: absolute;
    top: 14px;
    right: 60px;
    color: #73d0fa;
}
#register p{
    color: #aba9a9;
    font-size: 14px;
}
#register p a{
    color: #73d0fa;
    font-size: 16px;
    margin-left: 2px;
}
.popup .close-popup{
    position: absolute;
    top: 5%;
    right: 4%;
    background: url("../img/close.png") 0 0 no-repeat;
    width: 30px;
    height: 30px;
    background-size: cover;
}
.popup .close-popup:hover{
    text-decoration: none;
    color: #fff;
}


/* 响应式部分 */
@media screen and (max-width: 1919px){
    /* first screen */
    #fullpage #header .nav a{
        font-size: 17px;}
    #fullpage #header .register {
        margin-right: 10px;}
    #fullpage #header .login a, #fullpage #header .register a{
        font-size: 15px;}
    .slide-left {
        padding: 20% 4% 10% 12%;}
    .slide-left div{
        font-size: 17px;}
    .slide-left p {
        font-size: 17px;
        line-height: 2.3;}
    .slide-left h2 {
        font-size: 70px;}
    .slide-right .slider-banner1 {
        margin-top: 26%;
        margin-right: 20%;}
    .slide-right .slider-banner2 {
        margin-top: 30%;
        margin-right: 18%;}
    .slide-right .slider-banner3 {
        margin-top: 24%;
        margin-right: 12%;}
    .slide-right .slider-banner4 {
        margin-top: 30%;
        margin-left: 18%;}
    /* about us */
    #about-us .header-row h2, #about-us .header-row h3{
        font-size: 32px;}
    #about-us .content-row .float-right h4 {
        margin-bottom: 32px;
        font-size: 26px;
        line-height: 1.5;}
    #about-us .content-row .float-right h4:first-line{
        font-size: 28px;}
    #about-us .content-row .float-right p{
        line-height: 2;}
    #about-us .content-row .float-right a {
        width: 90px;
        height: 30px;
        font-size: 17px;
        line-height: 30px;}
    /* projects */
    #projects h2{
        font-size: 32px;}
    #projects ul li p {
        font-size: 17px;}
    #projects ul li .project-shadow a{
        font-size: 15px;}
    #projects .view-all-projects {
        font-size: 17px;}
    /* partners */
    #partners h2{
        font-size: 32px;}
    /* news */
    #news h2{
        font-size: 32px;}
    /* charging */
    #services {
        padding: 10% 28% 20% 50%;}
    #charging-items>h2{
        font-size: 32px;}
    #charging-items>h5 {
        font-size: 18px;}
    #charging-items>h5{
        margin-bottom: 30px;}
    #charging-items .charging-nav {
        margin: 0 auto 60px auto;}
    #charging-items .charging-container ul li .charge-top-part {
        padding-top: 32px;}
    #charging-items .charging-container ul li .charge-bottom-part p {
        line-height: 2.5;}
    /* team */
    #team .header-row h2, #team .header-row h3 {
        margin-bottom: 12px;
        font-size: 32px;}
    #team .header-row h3 {
        letter-spacing: 0;}
    #team .content-row ul li {
        padding-top: 18px;}
    #team .content-row ul li .member-name {
        font-size: 20px;}
    #team .content-row ul li .member-title {
        font-size: 17px;}
    #team .content-row .short-description {
        margin-top: 0;
        font-size: 20px;}
    /* footer */
    #index-footer .info .right p {
        font-size: 17px;}
}
@media screen and (max-width: 1679px){
    /* first screen */
    #fullpage #header .nav a{
        font-size: 16px;}
    #fullpage #header .register {
        margin-right: 10px;}
    #fullpage #header .login a, #fullpage #header .register a{
        font-size: 14px;}
    #fullpage #header .login, #fullpage #header .register {
        line-height: 60px;}
    .slide-left {
        padding: 18% 6% 10% 10%;}
    .slide-left div{
        font-size: 16px;
        margin-top: 16%;}
    .slide-left p {
        font-size: 16px;
        line-height: 2.3;}
    .slide-left h2 {
        font-size: 66px;}
    /* about us */
    #about-us .header-row h2, #about-us .header-row h3{
        font-size: 30px;}
    #about-us .content-row .float-right h4 {
        margin-bottom: 30px;
        font-size: 24px;}
    #about-us .content-row .float-right h4:first-line{
        font-size: 26px;}
    #about-us .content-row .float-right p{
        font-size: 17px;}
    /* projects */
    #projects h2{
        font-size: 30px;}
    #projects ul li p {
        font-size: 16px;}
    #projects ul li>i {
        width: 42px;
        height: 42px;
        line-height: 42px;
        font-size: 13px;}
    #projects ul li>i.icon-free {
        font-size: 12px;}
    #projects ul li .project-shadow a{
        width: 140px;
        height: 42px;
        top: 38%;
        margin-left: -70px;
        line-height: 42px;
        font-size: 14px;}
    #projects ul li .project-shadow a i {
        margin-right: 12px;
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        -o-transform: scale(0.8);
        transform: scale(0.8);}
    #projects .view-all-projects {
        font-size: 16px;}
    /* partners */
    #partners h2{
        font-size: 30px;}
    /* news */
    #news h2{
        font-size: 30px;}
    #news .news-swiper-container ul li {
        width: 380px;
        height: 218px;}
    #news .news-swiper-container ul li h3 {
        font-size: 18px;}
    #news .news-swiper-container ul li p {
        font-size: 12px;
        line-height: 1.5;}
    #news .news-swiper-container ul li .content div{
        width: 340px;}
    #news .news-swiper-container ul li:hover .content p{
        height: 70px;}
    #news .news-swiper-container ul li:hover .content div{
        width: 220px;}
    #news .news-swiper-container ul li .content div span {
        -webkit-transform: scale(0.9);
        -moz-transform: scale(0.9);
        -ms-transform: scale(0.9);
        -o-transform: scale(0.9);
        transform: scale(0.9);}
    #news .swiper-pagination .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        margin: 0 12px;}
    #news .news-swiper-container {
        height: 360px;}
    /* charging */
    #services {
        padding: 12% 26% 20% 50%;}
    #services h4 {
        margin-bottom: 10px;}
    #services h2 {
        font-size: 30px;
        margin-bottom: 50px;}
    #services p{
        font-size: 15px;
        line-height: 1.7;}
    #charging-items>h4{
        margin-bottom: 10px;}
    #charging-items>h2{
        font-size: 30px;}
    #charging-items>h5 {
        font-size: 17px;}
    #charging-items>h5{
        margin-bottom: 24px;}
    #charging-items .charging-nav {
        margin: 0 auto 60px auto;
        height: 50px;
        line-height: 50px;}
    /*#charging-items .charging-container ul li {*/
    /*    margin-right: 30px;*/
    /*    height: 27vw;}*/
    #charging-items .charging-container ul li .charge-top-part {
        padding-top: 20px;}
    #charging-items .charging-container ul li .charge-bottom-part p {
        font-size: 14px;}
    #charging-items .charging-container ul li .charge-bottom-part span {
        width: 140px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;}
    /* team */
    #team .header-row h2, #team .header-row h3 {
        margin-bottom: 10px;
        font-size: 30px;}
    #team .content-row ul li {
        padding-top: 16px;}
    #team .content-row ul li .member-name {
        font-size: 18px;}
    #team .content-row ul li .member-title {
        font-size: 16px;}
    #team .content-row .short-description {
        font-size: 18px;}
    /* footer */
    #index-footer .info .left div{
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        -o-transform: scale(0.8);
        transform: scale(0.8);
    }
    #index-footer .info .left .icon-address{
        -webkit-transform: scale(0.9);
        -moz-transform: scale(0.9);
        -ms-transform: scale(0.9);
        -o-transform: scale(0.9);
        transform: scale(0.9);
    }
    #index-footer .info .left p {
        font-size: 13px;
        text-align: center;}
    #index-footer .info .right p {
        font-size: 16px;}
}
@media screen and (max-width: 1599px){
    /* first screen */
    #fullpage #header {
        left: 8%;}
    #fullpage #header .nav li {
        margin-right: 2.6vw;}
    #fullpage #header .nav a{
        font-size: 15px;}
    .slide-left {
        padding: 18% 6% 10% 12%;}
    .slide-left div{
        font-size: 15px;
        margin-top: 16%;}
    .slide-left p {
        font-size: 15px;
        line-height: 2.3;}
    .slide-left h2 {
        font-size: 62px;}
    #fp-nav ul li a.active span, #fp-nav ul li:hover a.active span, .fp-slidesNav ul li a.active span, .fp-slidesNav ul li:hover a.active span{
        width: 10px;
        height: 10px;}
    /* about us */
    #about-us .header-row h2, #about-us .header-row h3{
        font-size: 28px;}
    #about-us .content-row .float-right h4 {
        font-size: 22px;}
    #about-us .content-row .float-right h4:first-line{
        font-size: 24px;}
    #about-us .content-row .float-right p{
        font-size: 16px;}
    #about-us .content-row .float-right a {
        font-size: 16px;}
    /* projects */
    #projects h2{
        font-size: 28px;}
    #projects ul li p {
        font-size: 15px;}
    #projects ul li>i {
        width: 36px;
        height: 36px;
        line-height: 36px;
        font-size: 12px;}
    #projects ul li .project-shadow a{
        font-size: 13px;}
    #projects .view-all-projects {
        font-size: 15px;}
    /* partners */
    #partners h2{
        font-size: 28px;}
    /* news */
    #news h2{
        font-size: 28px;}
    /* charging */
    #services {
        padding: 10% 24% 20% 50%;}
    #services h2 {
        font-size: 28px;}
    #services p{
        font-size: 14px;}
    /*#charging-items {*/
    /*    padding: 8% 21%;}*/
    /*#charging-items>h2{*/
    /*    font-size: 28px;}*/
    /*#charging-items>h5 {*/
    /*    font-size: 16px;}*/
    /*#charging-items .charging-nav a {*/
    /*    font-size: 15px;}*/
    /*#charging-items .charging-container ul li {*/
    /*    height: 29vw;}*/
    /*#charging-items .charging-container ul li .charge-bottom-part div {*/
    /*    margin-top: 14px;}*/
    /* team */
    #team .header-row h2, #team .header-row h3 {
        font-size: 28px;}
    #team .content-row ul li {
        padding-top: 14px;}
    #team .content-row ul li .member-name {
        font-size: 17px;}
    #team .content-row ul li .member-title {
        font-size: 15px;}
    #team .content-row .short-description {
        font-size: 17px;}
    /* footer */
    #index-footer .info ul li {
        margin-right: 14%;}
    #index-footer .info .right p {
        font-size: 14px;}
}
@media screen and (max-width: 1439px){
    /* first screen */
    #fullpage #header #logo img{
        width: 130px;}
    #fullpage #header .nav li {
        margin-right: 1.6vw;}
    #fullpage #header .nav a{
        font-size: 13px;}
    #fullpage #header .login, #fullpage #header .register {
        line-height: 50px;}
    #fullpage #header .login a, #fullpage #header .register a {
        font-size: 12px;
        padding: 3px 8px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;}
    .slide-left {
        padding: 16% 6% 10% 10%;}
    .slide-left div{
        font-size: 14px;}
    .slide-left p {
        font-size: 14px;}
    .slide-left h2 {
        font-size: 54px;
        margin-top: 6%;}
    .slide-right .slider-banner1 {
        margin-top: 18%;}
    .slide-right .slider-banner2 {
        margin-top: 22%;}
    .swiper-slide .slider-banner2{
        margin-top: 7vw;
    }
    .swiper-slide .slider-banner3 {
        width: 42vw;
    }
    .swiper-slide .slider-banner4 {
        width: 42vw;
    }
    .slide-right .slider-banner3 {
        margin-top: 16%;
        margin-right: 18%;}
    .slide-right .slider-banner4 {
        margin-top: 20%;
        margin-left: 14%;}
    #fp-nav ul li a.active span, #fp-nav ul li:hover a.active span, .fp-slidesNav ul li a.active span, .fp-slidesNav ul li:hover a.active span{
        width: 8px;
        height: 8px;
        margin:-4px 0 0 -4px;}
    /* about us */
    #about-us .header-row h2, #about-us .header-row h3{
        font-size: 26px;}
    #about-us .header-row {
        margin-bottom: 8%;}
    #about-us .content-row .float-right h4 {
        font-size: 20px;}
    #about-us .content-row .float-right h4:first-line{
        font-size: 22px;}
    #about-us .content-row .float-right p{
        font-size: 15px;}
    #about-us .content-row .float-right a {
        font-size: 15px;
        width: 80px;
        height: 26px;
        line-height: 26px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;}
    /* projects */
    #projects h2{
        font-size: 26px;}
    #projects ul li p {
        font-size: 14px;
        margin: 10px 0;}
    #projects ul li>i {
        width: 32px;
        height: 32px;
        line-height: 32px;
        bottom: 17%;}
    #projects ul li .project-shadow a {
        width: 130px;
        height: 38px;
        margin-left: -65px;
        line-height: 38px;
        font-size: 12px;}
    #projects .view-all-projects {
        font-size: 14px;}
    /* partners */
    #partners h2{
        font-size: 26px;}
    /* news */
    #news h2{
        font-size: 26px;}
    /* charging */
    #services h2 {
        font-size: 26px;
        margin-top: 0;}
    #services h4 {
        font-size: 14px;
        margin-bottom: 6px;}
    #services h4:before, #services h4:after {
        width: 14px;
        margin-right: 8px;}
    #services h4:after {
        margin-left: 8px;}
    #services p{
        font-size: 14px;}
    #charging-items>h2{
        font-size: 26px;}
    #charging-items>h5 {
        font-size: 15px;}
    #charging-items .charging-nav a {
        font-size: 14px;}
    #charging-items .charging-container ul li .charge-top-part h4 {
        font-size: 15px;
        margin-bottom: 10px;}
    #charging-items .charging-container ul li .charge-top-part h5 {
        font-size: 50px;}
    #charging-items .charging-container ul li .charge-bottom-part p {
        font-size: 13px;}
    #charging-items .charging-container ul li .charge-bottom-part span {
        width: 130px;
        height: 36px;
        line-height: 36px;
        font-size: 13px;}
    /* team */
    #team .header-row h2, #team .header-row h3 {
        font-size: 26px;}
    #team .content-row .member-container{
        height: 260px;}
    #team .content-row ul li {
        padding-top: 12px;}
    #team .content-row ul li .member-name {
        font-size: 16px;}
    #team .content-row ul li .member-title {
        font-size: 14px;}
    #team .content-row .short-description {
        font-size: 16px;}
    /* footer */
    #index-footer .logo img {
        width: 10vw;}
    #index-footer .info ul {
        padding: 0 16%;}
    #index-footer .info .left p {
        font-size: 12px;}
    #index-footer .info .right p {
        font-size: 13px;}
    #index-footer .info ul li:last-child .right p {
        font-size: 13px;}
    #index-footer .copyright{
        font-size: 12px;}
}
@media screen and (max-width: 1279px){
    /* first screen */
    #fullpage #header {
        left: 7%;}
    #fullpage #header #logo {
        margin-right: 4vw;}
    #fullpage #header #logo img {
        width: 120px;}
    .slide-left {
        padding: 16% 2% 10% 14%;}
    /* about us */
    #about-us .header-row h2, #about-us .header-row h3{
        font-size: 24px;}
    #about-us .content-row .float-right h4 {
        font-size: 19px;
        margin-bottom: 20px;}
    #about-us .content-row .float-right h4:first-line{
        font-size: 21px;}
    #about-us .content-row .float-right p{
        font-size: 14px;}
    #about-us .content-row .float-right>div {
        margin-top: 4%;}
    /* projects */
    #projects h2{
        font-size: 24px;}
    #projects ul li p {
        font-size: 13px;
        margin: 10px 0;}
    #projects ul li>i {
        bottom: 18%;}
    #projects .view-all-projects {
        font-size: 13px;}
    /* partners */
    #partners h2{
        font-size: 24px;}
    /* news */
    #news h2{
        font-size: 24px;}
    /* charging */
    #services h2 {
        font-size: 24px;
        margin-bottom: 20px;}
    #services h4 {
        font-size: 13px;}
    #services p{
        font-size: 12px;}
    #charging-items>h2{
        font-size: 24px;}
    #charging-items>h5 {
        font-size: 14px;}
    #charging-items .charging-nav {
        margin: 0 auto 50px auto;}
    #charging-items .charging-nav a {
        font-size: 13px;}
    #charging-items .charging-container ul li .charge-top-part {
        height: 33%;
        padding-top: 16px;}
    #charging-items .charging-container ul li .charge-top-part h4 {
        font-size: 14px;}
    #charging-items .charging-container ul li .charge-top-part h5 {
        font-size: 40px;}
    #charging-items .charging-container ul li .charge-top-part p {
        font-size: 13px;}
    #charging-items .charging-container ul li .charge-bottom-part p {
        font-size: 12px;
        line-height: 2.4;}
    #charging-items .charging-container ul li .charge-bottom-part span {
        width: 100px;
        height: 32px;
        line-height: 32px;
        font-size: 12px;}
    /* team */
    #team .header-row {
        margin-bottom: 4%;}
    #team .header-row h2, #team .header-row h3 {
        font-size: 24px;}
    #team .content-row .member-container {
        height: 230px;}
    #team .content-row ul li .member-name {
        font-size: 15px;
        margin-top: 12px;}
    #team .content-row ul li .member-title {
        font-size: 13px;}
    #team .content-row .short-description {
        font-size: 15px;}
    #team .content-row button{
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        -o-transform: scale(0.8);
        transform: scale(0.8);}
    /* footer */
    #index-footer .info ul {
        padding: 0 14%;}
    #index-footer .info ul li:last-child .right p {
        font-size: 12px;}
    #index-footer .copyright {
        padding: 18px 0 24px 0;}
}
@media screen and (max-width: 1151px){
    #fullpage #header .nav li {
        margin-right: 0.6vw;
    }
}
