/**
    移动端样式
 */

/**
    首页header部分
 */
#headerMobile{
    position: relative;
    background: -webkit-linear-gradient(-51deg, #4ec3f4, #1f9dcb, #0c4fab);
    background: -moz-linear-gradient(-51deg, #4ec3f4, #1f9dcb, #0c4fab);
    background: -o-linear-gradient(-51deg, #4ec3f4, #1f9dcb, #0c4fab);
    background: linear-gradient(-51deg, #4ec3f4, #1f9dcb, #0c4fab);
    height: 66.83vw;
}
/* logo行 */
#navMobile{
    position: absolute;
    top: 3.3vw;
    left: 0;
    width: 100vw;
    padding: 0 5.64vw;
    z-index: 10;
}
#navMobile .logo-mobile img{
    width: 18vw;
}
#navMobile .menu img{
    width: 5vw;
    margin-top: 0.8vw;
}
/* 侧边栏 */
#navMobile .nav{
    position: fixed;
    top: 0;
    right: -100%;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.4);
    z-index: -1;
}
#navMobile .nav.expanded{
    right: 0;
    z-index: 99;
}
#navMobile .mask{
    position: absolute;
    top: 0;
    left: 0;
    width: 60vw;
    height: 100vh;
    background: none;
    z-index: 99;
}
#navMobile .nav ul{
    position: fixed;
    top: 0;
    right: -100%;
    width: 30vw;
    height: 100vh;
    background: #ffffff;
    -webkit-box-shadow: -5vw 0 10vw rgba(0,0,0,0.15);
    -moz-box-shadow: -5vw 0 10vw rgba(0,0,0,0.15);
    box-shadow: -5vw 0 10vw rgba(0,0,0,0.15);
    z-index: 100;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    padding-top: 6vw;
}
#navMobile .nav.expanded ul{
    right: 0;
}
#navMobile .nav ul li{
    font-size: 0.875rem;
    line-height: 3;
    text-align: center;
}
/* 轮播图 */
#headerMobile .swiper-container{
    padding-top: 12vw;
    color: #ffffff;
    text-align: center;
}
#headerMobile .swiper-slide h3{
    font-size: 0.9375rem;
}
#headerMobile .swiper-slide p{
    margin: 2.5vw 0;
    font-size: 0.625rem;
}
#homeBannerMobile .slider-mobile-banner1{
    width: 85.51vw;
}
/**
    首页关于我们
 */
#aboutUsHome{
    text-align: center;
    padding: 4.83vw 0;
}
#aboutUsHome .header-row{
    color: #06277f;
}
#aboutUsHome .header-row .title{
    font-size: 0.875rem;
}
#aboutUsHome .header-row .sub-title{
    font-size: 0.75rem;
    margin-top: 0.25vw;
}
#aboutUsHome .header-row .title-desc{
    font-size: 0.6rem;
    margin-top: 1vw;
    line-height: 1.5;
}
#aboutUsHome .about-us-row{
    padding: 4vw 5vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#aboutUsHome .about-us-row .row-item{
    width: 31%;
    text-align: center;
}
#aboutUsHome .about-us-row .row-item .image img{
    height: 17vw;
}
#aboutUsHome .about-us-row .row-item .title{
    font-size: 0.6875rem;
    color: #040983;
    line-height: 1.2;
}
#aboutUsHome .about-us-row .row-item .desc{
    font-size: 0.375rem;
    color: #666666;
    line-height: 1.5;
    margin-top: 1vw;
    text-align: left;
    padding: 0 0.5vw;
}
#aboutUsHome .link-btn a{
    font-size: 0.625rem;
    color: #040983;
    border: 1px solid #2a36a4;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    padding: 2px 0.5rem;
}
/**
    首页合作项目
 */
#projectsHome{
    padding-bottom: 4vw;
}
#projectsHome .title{
    width: 100vw;
    height: 9.34vw;
    line-height: 9.34vw;
    background: url("../img/mobile/projectTitleBg.jpg");
    background-size: cover;
    color: #ffffff;
    font-size: 0.75rem;
    text-align: center;
}
#projectsHome .project-swiper-container .slide-image{
    padding: 2vw 1.5vw;
}
#projectsHome .project-swiper-container .slide-image img{
    width: 100%;
}
#projectsHome .project-swiper-container .content{
    padding: 2vw 3.7vw;
    font-size: 0.8125rem;
    text-indent: 0.8125rem;
    text-align: justify;
    line-height: 1.2;
    color: #666666;
}
/**
    首页最新资讯
 */
#newsMobile{
    padding: 5.15vw 0;
    background: #f3f3fd;
}
#newsMobile h4{
    font-size: 0.75rem;
    color: #666666;
    margin-bottom: 0.75vw;
    text-align: center;
}
#newsMobile h2{
    font-size: 0.875rem;
    color: #01237c;
    margin-bottom: 2vw;
    text-align: center;
}
#newsMobile .news-swiper-container ul li{
    height: 56vw;
    background: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.15);
    -moz-box-shadow: 0 0 10px rgba(0,0,0,0.15);
    box-shadow: 0 0 10px rgba(0,0,0,0.15);
    display: flex;
    overflow: hidden;
    -webkit-transform: scale(0.85);
    -moz-transform: scale(0.85);
    -ms-transform: scale(0.85);
    -o-transform: scale(0.85);
    transform: scale(0.85);
}
#newsMobile .news-swiper-container ul li .side-image{
    width: 0;
}
#newsMobile .news-swiper-container ul li .side-image img{
    width: 100%;
}
#newsMobile .news-swiper-container ul li .content{
    width: 100%;
    position: relative;
    padding: 4vw;
}
#newsMobile .news-swiper-container ul li:hover .side-image, #newsMobile .news-swiper-container ul li.active .side-image{
    width: 35%;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}
#newsMobile .news-swiper-container ul li:hover .content, #newsMobile .news-swiper-container ul li.active .content{
    width: 65%;
}
#newsMobile .news-swiper-container ul li:hover .content p, #newsMobile .news-swiper-container ul li.active .content p{
    height: 78px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
#newsMobile .news-swiper-container ul li:hover .content div, #newsMobile .news-swiper-container ul li.active .content div{
    width: 220px;
}
#newsMobile .news-swiper-container ul li h3{
    font-size: 20px;
    margin-top: 0;
    color: #404040;
}
#newsMobile .news-swiper-container ul li h5{
    font-size: 12px;
    color: #666;
}
#newsMobile .news-swiper-container ul li hr{
    width: 60px;
    height: 1px;
    background: #6e45e2;
    margin-left: 0;
    margin-bottom: 12px;
    margin-top: 16px;
}
#newsMobile .news-swiper-container ul li p{
    font-size: 12px;
    line-height: 1.7;
    color: #666;
}
#newsMobile .news-swiper-container ul li .content div{
    position: absolute;
    left: 4vw;
    bottom: 4vw;
    width: 360px;
    line-height: 26px;
    font-size: 0.75rem;
    color: #666666;
}
#newsMobile .news-swiper-container ul li .content div a{
    color: #9fa4af;
    font-size: 12px;
}
#newsMobile .news-swiper-container ul li .content div i{
    display: inline-block;
    width: 13px;
    height: 13px;
    background: url("./sprite.png") -78px 0 no-repeat;
    margin-right: 16px;
    vertical-align: middle;
}
#newsMobile .news-swiper-container ul li .content div span{
    display: inline-block;
    width: 26px;
    height: 26px;
    background: url("./sprite.png") 0 0 no-repeat;
    float: right;
}
#newsMobile .news-swiper-container ul li:nth-child(2) .content div span, #newsMobile .news-swiper-container ul li:nth-child(6) .content div span{
    background-position: -26px 0;
}
#newsMobile .news-swiper-container ul li:nth-child(3) .content div span{
    background-position: -52px 0;
}
#newsMobile .news-swiper-container ul li>a{
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
#newsMobile .news-swiper-pagination{
    text-align: center;
}
#newsMobile .news-swiper-pagination .swiper-pagination-bullet{
    width: 1.5vw;
    height: 1.5vw;
    background: #01237c;
}
/**
    首页收费计划
 */
#chargingHome{
    padding: 5.15vw 0;
    text-align: center;
    position: relative;
}
#chargingHome .bg-container{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100vw;
    z-index: 0;
}
#chargingHome .bg-container img{
    width: 100%;
}
#chargingHome>h4{
    font-size: 0.75rem;
    color: #666666;
    margin-bottom: 0.75vw;
}
#chargingHome>h2{
    color: #01237c;
    font-size: 0.875rem;
    margin-bottom: 1.5vw;
}
#chargingHome>h5{
    font-size: 0.6875rem;
    color: #404040;
    margin-bottom: 5vw;
}
#chargingHome .charging-nav{
    width: 60vw;
    height: 2rem;
    line-height: 2rem;
    margin: 0 auto 4vw auto;
    border-bottom: 1px solid #e6e6e6;
}
#chargingHome .charging-nav span{
    float: left;
    width: 33.33%;
    height: 100%;
    font-size: 0.75rem;
    color: #666;
    text-align: center;
}
#chargingHome .charging-nav span.charging-active{
    border-bottom: 2px solid #01237c;
}
#chargingHome .charging-container-mobile{
    margin: 12vw 0;
}
#chargingHome .charging-container-mobile ul li{
    height: 54vw;
    -webkit-border-radius: 2vw;
    -moz-border-radius: 2vw;
    border-radius: 2vw;
    border: 1px solid #808fd1;
    background: #fff;
    text-align: center;
    overflow: hidden;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}
#chargingHome .charging-container-mobile ul li.swiper-slide-active{
    -webkit-transform: scale(1.25);
    -moz-transform: scale(1.25);
    -ms-transform: scale(1.25);
    -o-transform: scale(1.25);
    transform: scale(1.25);
}
#chargingHome .charging-container-mobile ul li.swiper-slide-prev{
    margin-left: -10%;
    margin-right: 10%;
}
#chargingHome .charging-container-mobile ul li.swiper-slide-next{
    margin-left: 10%;
}
#chargingHome .charging-container-mobile ul li .charge-top-part{
    height: 35%;
    background: #f3f3fd;
    padding-top: 3vw;
    color: #404040;
}
#chargingHome .charging-container-mobile ul li .charge-top-part h4{
    font-size: 0.625rem;
    margin-bottom: 1.5vw;
}
#chargingHome .charging-container-mobile ul li .charge-top-part h5{
    font-size: 1.25rem;
    margin-bottom: 1vw;
    line-height: 1;
}
#chargingHome .charging-container-mobile ul li .charge-top-part p{
    font-size: 0.5rem;
}
#chargingHome .charging-container-mobile ul li .charge-bottom-part p{
    font-size: 0.525rem;
    line-height: 2;
    color: #808080;
    margin-top: 2vw;
}
#chargingHome .charging-container-mobile ul li .charge-bottom-part div{
    margin-top: 2vw;
}
#chargingHome .charging-container-mobile ul li .charge-bottom-part span{
    display: inline-block;
    width: 20vw;
    height: 5.6vw;
    line-height: 5.6vw;
    border: 1px solid #01237c;
    font-size: 0.625rem;
    color: #141a81;
}
#chargingHome .charging-container-mobile ul li.swiper-slide-active .charge-top-part, #chargingHome .charging-container-mobile ul li.swiper-slide-active .charge-bottom-part span{
    background: -webkit-linear-gradient(150deg, #3e85c8, #5bb8ed);
    background: -moz-linear-gradient(150deg, #3e85c8, #5bb8ed);
    background: -ms-linear-gradient(150deg, #3e85c8, #5bb8ed);
    background: -o-linear-gradient(150deg, #3e85c8, #5bb8ed);
    background: linear-gradient(150deg, #3e85c8, #5bb8ed);
    border: 0;
    color: #fff;
}
/**
    首页合作伙伴
 */
#partnersHome{
    padding: 5.15vw 0;
    text-align: center;
}
#partnersHome h4{
    font-size: 0.75rem;
    color: #666666;
    margin-bottom: 0.75vw;
}
#partnersHome h2{
    width: 100vw;
    height: 9.34vw;
    line-height: 9.34vw;
    background: url("../img/mobile/projectTitleBg.jpg");
    background-size: cover;
    color: #ffffff;
    font-size: 0.75rem;
    text-align: center;
}
#partnersHome ul{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 10%;
    margin: 5.15vw 0;
}
#partnersHome ul li{
    width: 25%;
}
#partnersHome ul li img{
    width: 100%;
}
#partnersHome ul li:last-child img{
    width: 90%;
}
#partnersHome .joinUs{
    margin-top: 5.15vw;
    width: 100%;
}
/**
    首页footer部分
 */
#footerHome{
    padding: 7.1vw 0 6vw 0;
    background: #046cba;
    color: #ffffff;
    text-align: center;
}
#footerHome .info{
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
}
#footerHome .info li{
    width: 30%;
}
#footerHome .info li .top{
    font-size: 0.6875rem;
    color: #a8ceff;
}
#footerHome .info .top div{
    width: 36px;
    height: 36px;
    margin: 0 auto;
    background: url(sprite.png) 0 -33px no-repeat;
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
}
#footerHome .info .top .icon-mail{
    background-position: -36px -33px;
}
#footerHome .info .top .icon-address{
    background-position: -72px -33px;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
}
#footerHome .info .top p{
    margin-top: 0.5vw;
}
#footerHome .info li .bottom{
    font-size: 0.575rem;
    margin-top: 2vw;
    line-height: 1.2;
}
#footerHome .copyright{
    font-size: 0.6875rem;
    margin-top: 5vw;
}

/**
    公共header部分
 */
.header-container-mobile{
    height: 44.28vw;
    background: -webkit-linear-gradient(90deg, #155cb4, #45b4eb);
    background: -moz-linear-gradient(90deg, #155cb4, #45b4eb);
    background: -o-linear-gradient(90deg, #155cb4, #45b4eb);
    background: linear-gradient(90deg, #155cb4, #45b4eb);
    position: relative;
    padding-top: 1px;
    color: #ffffff;
    text-align: center;
}
.header-container-mobile .center-bg-img {
    position: absolute;
    width: 44vw;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.header-container-mobile h1{
    font-size: 0.9375rem;
    margin-top: 18vw;
}
.header-container-mobile h2{
    font-size: 0.675rem;
    margin-top: 1vw;
    color: rgba(255,255,255,0.4);
}

/**
    关于我们页文字描述部分
 */
#aboutUsPageMobile .introduction{
    padding: 4vw 5.64vw 12vw 5.64vw;
    color: #666666;
    font-size: 0.75rem;
    text-align: justify;
    line-height: 1.5;
}
#aboutUsPageMobile .introduction .info{
    border: 1px solid #cccccc;
    padding: 1.5vw 1vw;
    font-size: 0.7rem;
    margin-bottom: 4vw;
}
#aboutUsPageMobile .introduction p{
    text-indent: 1.5rem;
}
/**
    关于我们页banner部分
 */
#aboutUsPageMobile .banner{
    height: 15.625vw;
    background: -webkit-linear-gradient(60deg, #1055b0, #4bbdf1);
    background: -moz-linear-gradient(60deg, #1055b0, #4bbdf1);
    background: -o-linear-gradient(60deg, #1055b0, #4bbdf1);
    background: linear-gradient(60deg, #1055b0, #4bbdf1);
    color: #fff;
    display: flex;
    text-align: center;
    padding: 0 14vw;
    align-items: center;
    justify-content: space-between;
}
#aboutUsPageMobile .banner li h4{
    font-size: 1rem;
    margin-bottom: 0.5vw;
}
#aboutUsPageMobile .banner li h4 span{
    font-size: 0.25rem;
    margin-left: 1vw;
}
#aboutUsPageMobile .banner li p{
    font-size: 0.45rem;
}
/**
    关于我们页技术服务部分
 */
#aboutUsPageMobile .tech>h2{
    font-size: 0.75rem;
    color: #000000;
    text-align: center;
    margin-top: 4.8vw;
}
#aboutUsPageMobile .tech>h3{
    font-size: 0.45rem;
    color: #666;
    text-align: center;
    margin-top: 3vw;
    margin-bottom: 8vw;
}
#aboutUsPageMobile .tech ul{
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    padding: 0 5.6vw;
    margin-bottom: 17vw;
}
#aboutUsPageMobile .tech ul li{
    flex: 0 0 50%;
    padding: 6.4vw 1vw;
}
#aboutUsPageMobile .tech ul li:nth-child(1),
#aboutUsPageMobile .tech ul li:nth-child(2){
    padding-top: 0;
}
#aboutUsPageMobile .tech ul li:nth-child(5),
#aboutUsPageMobile .tech ul li:nth-child(6){
    padding-bottom: 0;
}
#aboutUsPageMobile .tech ul li:nth-child(1),
#aboutUsPageMobile .tech ul li:nth-child(2),
#aboutUsPageMobile .tech ul li:nth-child(3),
#aboutUsPageMobile .tech ul li:nth-child(4){
    border-bottom: 1px solid #999999;
}
#aboutUsPageMobile .tech ul li:nth-child(1),
#aboutUsPageMobile .tech ul li:nth-child(3),
#aboutUsPageMobile .tech ul li:nth-child(5){
    border-right: 1px solid #999999;
}
#aboutUsPageMobile .tech ul li h3{
    font-size: 0.875rem;
    color: #000;
    margin-bottom: 2vw;
}
#aboutUsPageMobile .tech ul li p{
    font-size: 0.625rem;
    color: #666;
    line-height: 1.8;
}
#aboutUsPageMobile .tech ul li img{
    width: 14.8vw;
}
